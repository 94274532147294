<template>
  <div class="container">
    <div class="title">{{ $t("companyProfiles") }}</div>
    <div class="line"></div>
    <div v-loading="loading">
      <div class="content">
        <div v-if="dataInfo.profile" v-html="dataInfo.profile"></div>
        <div v-else>-</div>
      </div>
      <div class="footer marginT24">
        <div class="footTitle footWidth1">
          <div class="footContent1" :class="LOCALE">
            {{ $t("createTimecompany") }}
          </div>
          <div class="footContent2">{{ dataInfo.found_year | textFormat }}</div>
        </div>
        <div class="footTitle footWidth2">
          <div class="footContent1" :class="LOCALE">
            {{ $t("enterprisescale") }}
          </div>
          <div class="footContent2">
            {{ dataInfo.scale | staffsizeFormat(LOCALE) }}
          </div>
        </div>
        <div class="footTitle footWidth3">
          <div class="footContent1" :class="LOCALE">{{ $t("registered") }}</div>
          <div class="footContent2">
            {{ dataInfo.registered_capital | textFormat }}
          </div>
        </div>
      </div>
      <div class="footer marginT16">
        <div class="footTitle footWidth1">
          <div class="footContent1" :class="LOCALE">
            {{ $t("contactPhone") }}
          </div>
          <div class="footContent2">
            <span v-if="IS_LOGIN">{{ dataInfo.telephone | textFormat }}</span>
            <span class="to_login" v-else type="text" @click="to_login">{{
              $t("pleaseSignIn")
            }}</span>
          </div>
        </div>
        <div class="footTitle footWidth2">
          <div class="footContent1" style="width: 30%" :class="LOCALE">
            {{ $t("email") }}
          </div>
          <div class="footContent2" style="width: 70%">
            <span v-if="IS_LOGIN" :title="dataInfo.email | textFormat">{{
              dataInfo.email | textFormat
            }}</span>
            <span class="to_login" v-else type="text" @click="to_login">{{
              $t("pleaseSignIn")
            }}</span>
          </div>
        </div>
        <div class="footTitle footWidth3">
          <div class="footContent1" style="width: 30%" :class="LOCALE">
            {{ $t("address") }}
          </div>
          <div
            class="footContent2"
            style="width: 70%"
            :title="
              dataInfo.address_en | priorFormat(dataInfo.address_zh, LOCALE)
            "
          >
            {{ dataInfo.address_en | priorFormat(dataInfo.address_zh, LOCALE) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { priorFormat } from "@/basePlugins/filters";
export default {
  name: "companyBrief",
  props: {
    company_id: {
      type: String | Number,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      dataInfo: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    to_login() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
    async getData() {
      let params = {
        company_id: this.company_id,
      };
      this.loading = true;
      try {
        let res = await this.$store.dispatch(
          "API_company/getCompanyBrief",
          params
        );
        this.loading = false;
        if (res.success) {
          res.data.profile = priorFormat(
            res.data.profile_en,
            res.data.profile_zh,
            this.LOCALE
          );
          if (res.data.found_year === 0) {
            res.data.found_year = "";
          }
          this.dataInfo = Object.assign({}, res.data);
        }
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 24px 24px 32px;
  background: #ffffff;
  .title {
    color: #333333;
    font-size: 16px;
    font-weight: 800;
  }
  .to_login {
    cursor: pointer;
    color: #0083f6;
  }
  .line {
    height: 1px;
    background: #dcdfe6;
    width: 100%;
    margin-top: 20px;
  }
  .content {
    margin-top: 16px;
    color: #333333;
    margin-bottom: 8px;
    word-break: break-all;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    .footTitle {
      display: flex;
      .footContent1 {
        font-weight: 800;
        color: #333333;
      }
      .zh {
        width: 76px;
      }
      .en {
        width: 130px;
      }
      .footContent2 {
        font-weight: 400;
        color: #1f292e;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .footWidth1 {
      width: 31%;
    }
    .footWidth2 {
      width: 31%;
    }
    .footWidth3 {
      width: 38%;
    }
  }
}
.marginT24 {
  margin-top: 24px;
}
.marginT16 {
  margin-top: 16px;
}
</style>